.video-container {
  position: relative;
  overflow: hidden;
  height: 350px;
  @media only screen and (max-width: 630px) {
    height: auto;
  }

  .video {
    width: 100%;
    height: auto;
  }

  .header-container {
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.7);
    height: 100%;
    display: flex;
    align-items: center;

    .header-inner-contents {
      @media only screen and (max-width: 500px) {
        h2 {
          font-size: 1.2rem;
        }
        p {
          font-size: 0.9rem;
        }
      }
    }
  }
}

@media only screen and (max-width: $medium) {
  .header-container {
    padding: 1rem 0 !important;
  }

  #logo {
    text-align: center;
    margin: 0;

    img {
      display: inline-block;
      width: 50px;
      height: 50px;
    }
  }
}

#bottle-visual {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;

  @media only screen and (max-width: $medium) {
    padding: 2rem 0;
  }

  img {
    max-height: 300px;
    width: auto;
    display: inline-block;
  }

  img#bottle1 {
    animation: float-up 10s ease-in-out infinite;
  }

  img#bottle2 {
    animation: float-down 10s ease-in-out infinite;
  }
}

@keyframes float-up {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes float-down {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(20px);
  }
  100% {
    transform: translatey(0px);
  }
}
