.complete-section {
    .top-container {
        text-align: center;
        h2 {
            font-weight: 300 !important;
            line-height: 1.5;
        }
        .success-icon {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            margin-bottom: 25px;
        }
        .weight-target-status {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 4rem 0;
            .weight-target-status-box {
                background: #fff;
                border: 1px solid #ededed;
                border-radius: 10px;
                text-align: center;
                padding: 15px;
                overflow: hidden;
                color: #000;
                width: 200px;
                margin-right: 1rem;
                &:nth-last-child(1) {
                    margin: 0;
                }
                &.box-diff {
                    width: 250px;
                    background: $secondary;
                    border-color: $primary;
                    color: $primary;
                }
                .fontW-bold {
                    font-weight: 700;
                }
            }
        }
        .text-gradient {
            background: linear-gradient(90deg, rgba(213, 229, 243, 1) 0%, rgba(45, 120, 247, 0.4) 100%);
            padding: 2px 7px;
            border-radius: 5px;
        }
        @media only screen and (max-width: $small) {
            .chakra-container {
                padding: 0;
            }
            .weight-target-status {
                display: inline-block;
                width: 100%;
                margin: 2rem 0;
                .weight-target-status-box {
                    display: inline-block;
                    width: 47%;
                    margin-right: 0;
                    &:nth-child(1) {
                        margin-right: 1rem;
                    }
                    &.box-diff {
                        width: 100%;
                        margin-top: 1rem !important;
                    }
                }
            }
        }
    }
    .graph-container {
        box-shadow: 5px 4px 30px 14px rgba(0, 0, 0, 0.15);
        border-radius: 15px;
        overflow: hidden;
        .estimated_dates_wrap {
            margin-top: 1rem;
            .current_date {
                width: 50%;
                float: left;
                text-align: left;
                padding-left: 15px;
                padding-bottom: 10px;
            }
            .goal_dates {
                width: 50%;
                float: left;
                text-align: right;
                padding-right: 15px;
                padding-bottom: 10px;
            }
            .current_date span,
            .goal_dates span {
                background: rgba(0, 0, 0, 0.07);
                padding: 2px 5px;
                border-radius: 5px;
                font-size: 0.9rem;
                color: #1e1e1e;
            }
        }
        .svg-graph {
            width: 100%;
            height: auto;
            .verticalYJMM {
                stroke-width: 1.5px;
                stroke-dasharray: 0 3;
            }
            .svgText {
                text-anchor: middle;
                stroke-width: 1.419;
                font-weight: 700;
                &.cSad {
                    fill: $primary;
                }
                &.cHappy {
                    fill: $primary;
                }
            }
            .face_1 {
                transform: matrix(0.75, 0, 0, 0.75, 17.845251, 13.566498);
            }
            .face_2 {
                transform: matrix(0.75, 0, 0, 0.75, 124.76877, 58.71051);
            }
        }
        .svg-humanbody {
            background-color: #1e1e1e;
            padding: 1.5rem;
        }
        @media only screen and (max-width: $medium) {
            flex-direction: column;
            & > div {
                width: 100%;
            }
        }
    }
    .bottom-container {
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 362px;
        padding-bottom: 65px;
        background-color: #000000 !important;
        margin-top: -5rem;
        text-align: center;
        @media only screen and (max-width: $medium) {
            padding-top: 300px;
        }
    }
    .badge-container img {
        width: 180px;
        margin: auto;
    }
}

.product-image {
    padding: 1.5rem 0;
    max-height: 280px;
    width: auto!important;
}
